import bwipjs from "bwip-js"
const init = function(){

  document.addEventListener("turbolinks:load", function() {
    var qrcodecanvas;
    if (qrcodecanvas = document.getElementById("qrcodecanvas")){
      const create_qr_code = function(coupon_url) {
        bwipjs.toCanvas('qrcodecanvas', {
          bcid: "qrcode",
          text: coupon_url,
          parse: true,
          scale: 2,
          eclevel: "L"
        });
      };

      create_qr_code(qrcodecanvas.getAttribute("value"));
    }
  })
}

export default { init }
